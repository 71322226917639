import { useSession } from "next-auth/react";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import Button from "../components/forms/Button";
import HomeLayout from "../components/layout/HomeLayout";
import paths from "../config/paths";
import image from "../public/home-image.jpg";

export default function Home() {
  return (
    <HomeLayout>
      <Head>
        <title>SIVo CRUZ ROJA URUGUAYA</title>
        <meta
          name="description"
          content="Sistema de información de Voluntariado de Cruz Roja Uruguaya"
        />
        <link rel="icon" href="/icon-192x192.png" />
      </Head>

      <main className="grow container flex items-center py-6">
        <div className="lg:w-1/2">
          <h2 className="font-black text-2xl lg:text-4xl mb-6 lg:whitespace-pre-line whitespace-normal">
            Sistema de Información de Voluntariado
          </h2>
          <p className="text-justify">
            Sean bienvenidas y bienvenidos al Sistema de Información de
            Voluntariado (SIVo) donde podrás compartir con tu filial toda la
            información necesaria para poder desarrollar las actividades en
            terreno, cumpliendo con las normas de seguridad de nuestra
            organización.
          </p>
          <Link href={paths.register}>
            <Button className="w-full mt-10">
              Registrarme como persona voluntaria
            </Button>
          </Link>
        </div>
        <Image src={image} priority alt="" className="hidden lg:block w-1/2" />
      </main>
    </HomeLayout>
  );
}
